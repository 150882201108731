import { default as adminNHn7qbWHOYMeta } from "/home/saimo/WebstormProjects/hub-rma-service/pages/admin.vue?macro=true";
import { default as indexeDgZw3LIWhMeta } from "/home/saimo/WebstormProjects/hub-rma-service/pages/index.vue?macro=true";
import { default as legalDUQs7501qqMeta } from "/home/saimo/WebstormProjects/hub-rma-service/pages/legal.vue?macro=true";
import { default as logindba6upN6cLMeta } from "/home/saimo/WebstormProjects/hub-rma-service/pages/login.vue?macro=true";
import { default as privacykTqkkTc0EVMeta } from "/home/saimo/WebstormProjects/hub-rma-service/pages/privacy.vue?macro=true";
import { default as status3lBHwrG8V2Meta } from "/home/saimo/WebstormProjects/hub-rma-service/pages/repair/status.vue?macro=true";
import { default as _91step_930A1FCSAcGuMeta } from "/home/saimo/WebstormProjects/hub-rma-service/pages/repair/step/[step].vue?macro=true";
import { default as scan2mKTXZVXXYMeta } from "/home/saimo/WebstormProjects/hub-rma-service/pages/shop/scan.vue?macro=true";
import { default as scanwvO4wrT91MMeta } from "/home/saimo/WebstormProjects/hub-rma-service/pages/workshop/scan.vue?macro=true";
export default [
  {
    name: adminNHn7qbWHOYMeta?.name ?? "admin___en",
    path: adminNHn7qbWHOYMeta?.path ?? "/en/admin",
    meta: adminNHn7qbWHOYMeta || {},
    alias: adminNHn7qbWHOYMeta?.alias || [],
    redirect: adminNHn7qbWHOYMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: adminNHn7qbWHOYMeta?.name ?? "admin___de",
    path: adminNHn7qbWHOYMeta?.path ?? "/admin",
    meta: adminNHn7qbWHOYMeta || {},
    alias: adminNHn7qbWHOYMeta?.alias || [],
    redirect: adminNHn7qbWHOYMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: indexeDgZw3LIWhMeta?.name ?? "index___en",
    path: indexeDgZw3LIWhMeta?.path ?? "/en",
    meta: indexeDgZw3LIWhMeta || {},
    alias: indexeDgZw3LIWhMeta?.alias || [],
    redirect: indexeDgZw3LIWhMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexeDgZw3LIWhMeta?.name ?? "index___de",
    path: indexeDgZw3LIWhMeta?.path ?? "/",
    meta: indexeDgZw3LIWhMeta || {},
    alias: indexeDgZw3LIWhMeta?.alias || [],
    redirect: indexeDgZw3LIWhMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legalDUQs7501qqMeta?.name ?? "legal___en",
    path: legalDUQs7501qqMeta?.path ?? "/en/legal",
    meta: legalDUQs7501qqMeta || {},
    alias: legalDUQs7501qqMeta?.alias || [],
    redirect: legalDUQs7501qqMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: legalDUQs7501qqMeta?.name ?? "legal___de",
    path: legalDUQs7501qqMeta?.path ?? "/legal",
    meta: legalDUQs7501qqMeta || {},
    alias: legalDUQs7501qqMeta?.alias || [],
    redirect: legalDUQs7501qqMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: logindba6upN6cLMeta?.name ?? "login___en",
    path: logindba6upN6cLMeta?.path ?? "/en/login",
    meta: logindba6upN6cLMeta || {},
    alias: logindba6upN6cLMeta?.alias || [],
    redirect: logindba6upN6cLMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logindba6upN6cLMeta?.name ?? "login___de",
    path: logindba6upN6cLMeta?.path ?? "/login",
    meta: logindba6upN6cLMeta || {},
    alias: logindba6upN6cLMeta?.alias || [],
    redirect: logindba6upN6cLMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/login.vue").then(m => m.default || m)
  },
  {
    name: privacykTqkkTc0EVMeta?.name ?? "privacy___en",
    path: privacykTqkkTc0EVMeta?.path ?? "/en/privacy",
    meta: privacykTqkkTc0EVMeta || {},
    alias: privacykTqkkTc0EVMeta?.alias || [],
    redirect: privacykTqkkTc0EVMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacykTqkkTc0EVMeta?.name ?? "privacy___de",
    path: privacykTqkkTc0EVMeta?.path ?? "/privacy",
    meta: privacykTqkkTc0EVMeta || {},
    alias: privacykTqkkTc0EVMeta?.alias || [],
    redirect: privacykTqkkTc0EVMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: status3lBHwrG8V2Meta?.name ?? "repair-status___en",
    path: status3lBHwrG8V2Meta?.path ?? "/en/repair/status",
    meta: status3lBHwrG8V2Meta || {},
    alias: status3lBHwrG8V2Meta?.alias || [],
    redirect: status3lBHwrG8V2Meta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/repair/status.vue").then(m => m.default || m)
  },
  {
    name: status3lBHwrG8V2Meta?.name ?? "repair-status___de",
    path: status3lBHwrG8V2Meta?.path ?? "/repair/status",
    meta: status3lBHwrG8V2Meta || {},
    alias: status3lBHwrG8V2Meta?.alias || [],
    redirect: status3lBHwrG8V2Meta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/repair/status.vue").then(m => m.default || m)
  },
  {
    name: _91step_930A1FCSAcGuMeta?.name ?? "repair-step-step___en",
    path: _91step_930A1FCSAcGuMeta?.path ?? "/en/repair/step/:step()",
    meta: _91step_930A1FCSAcGuMeta || {},
    alias: _91step_930A1FCSAcGuMeta?.alias || [],
    redirect: _91step_930A1FCSAcGuMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/repair/step/[step].vue").then(m => m.default || m)
  },
  {
    name: _91step_930A1FCSAcGuMeta?.name ?? "repair-step-step___de",
    path: _91step_930A1FCSAcGuMeta?.path ?? "/repair/step/:step()",
    meta: _91step_930A1FCSAcGuMeta || {},
    alias: _91step_930A1FCSAcGuMeta?.alias || [],
    redirect: _91step_930A1FCSAcGuMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/repair/step/[step].vue").then(m => m.default || m)
  },
  {
    name: scan2mKTXZVXXYMeta?.name ?? "shop-scan___en",
    path: scan2mKTXZVXXYMeta?.path ?? "/en/shop/scan",
    meta: scan2mKTXZVXXYMeta || {},
    alias: scan2mKTXZVXXYMeta?.alias || [],
    redirect: scan2mKTXZVXXYMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/shop/scan.vue").then(m => m.default || m)
  },
  {
    name: scan2mKTXZVXXYMeta?.name ?? "shop-scan___de",
    path: scan2mKTXZVXXYMeta?.path ?? "/shop/scan",
    meta: scan2mKTXZVXXYMeta || {},
    alias: scan2mKTXZVXXYMeta?.alias || [],
    redirect: scan2mKTXZVXXYMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/shop/scan.vue").then(m => m.default || m)
  },
  {
    name: scanwvO4wrT91MMeta?.name ?? "workshop-scan___en",
    path: scanwvO4wrT91MMeta?.path ?? "/en/workshop/scan",
    meta: scanwvO4wrT91MMeta || {},
    alias: scanwvO4wrT91MMeta?.alias || [],
    redirect: scanwvO4wrT91MMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/workshop/scan.vue").then(m => m.default || m)
  },
  {
    name: scanwvO4wrT91MMeta?.name ?? "workshop-scan___de",
    path: scanwvO4wrT91MMeta?.path ?? "/workshop/scan",
    meta: scanwvO4wrT91MMeta || {},
    alias: scanwvO4wrT91MMeta?.alias || [],
    redirect: scanwvO4wrT91MMeta?.redirect,
    component: () => import("/home/saimo/WebstormProjects/hub-rma-service/pages/workshop/scan.vue").then(m => m.default || m)
  }
]