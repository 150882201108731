import countries from 'i18n-iso-countries'
import en from 'i18n-iso-countries/langs/en.json'
import de from 'i18n-iso-countries/langs/de.json'
import { labelCountries, pickupCountries } from '~/server/utils/order/types'

export default defineNuxtPlugin({
  name: 'i18n-countries',
  async setup () {

  },
  hooks: {
    'app:created' (app) {
      countries.registerLocale(en)
      countries.registerLocale(de)

      const allCountries = [...labelCountries, ...pickupCountries]

      const filteredCountries = Object.keys(countries.getNames('en')).reduce((acc: any, code) => {
        if (allCountries.includes(code)) {
          acc[code] = countries.getName(code, 'en')
        }
        return acc
      }, {})

      app.provide('countries', filteredCountries)
    }
  }
})
