import { usePermissions } from '~/composables/permissions'

export default defineNuxtRouteMiddleware(async () => {
  const user = useUser()
  const res = await $fetch('/api/user', {
    method: 'GET',
    redirect: 'manual'
  })

  if (!res || !res.user?.userId) {
    return
  }

  if (!res.user?.userId) { /* empty */ } else {
    user.value = res.user ?? null
    const userPermissions = usePermissions()
    userPermissions.value = user.value?.permissions ?? []
  }
})
