import type { User } from 'lucia'
import usePrisma from '~/composables/usePrisma'

export const useUser = () => {
  return useState<User | null>('user', () => null)
}

export const useUserLoggedIn = () => {
  return computed(() => {
    return useUser().value?.email !== null
  })
}

export const useAuthenticatedUser = () => {
  const user = useUser()
  return computed(() => {
    const userValue = unref(user)
    if (!userValue) {
      throw createError(
        'useAuthenticatedUser() can only be used in protected pages'
      )
    }
    return userValue
  })
}

export const useUserPermissions = () => {
  const userPermissions = usePermissions()
  return computed(() => {
    return userPermissions.value
  })
}

export const useGetUserPermissions = async () => {
  await usePrisma().user.findFirst({
    where: {
      roleId: 'ADMIN'
    },
    include: {
      role: true
    }
  })
}

export const useGetRolePermissions = async (roleId: string) => {
  const role = await usePrisma().role.findFirst({
    where: {
      id: roleId
    },
    include: {
      powers: true
    }
  })
  if (!role) {
    throw createError('Role not found')
  }
  return role.powers.map(permission => permission.powerId) ?? []
}

export const useUserAdmin = () => {
  const user = useUser()
  const userValue = unref(user)
  if (!userValue) {
    throw createError('useUserAdmin() can only be used in protected pages')
  }
  return userValue.roleId === 'ADMIN'
}
