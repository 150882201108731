import revive_payload_client_8UYfML9Zsn from "/home/saimo/WebstormProjects/hub-rma-service/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_rnlbeninscff3gemmrvl6hp45u/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_AxfMKDgrzJ from "/home/saimo/WebstormProjects/hub-rma-service/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_rnlbeninscff3gemmrvl6hp45u/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_eTKVWLTuCf from "/home/saimo/WebstormProjects/hub-rma-service/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_rnlbeninscff3gemmrvl6hp45u/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_7NquL3Fccb from "/home/saimo/WebstormProjects/hub-rma-service/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_rnlbeninscff3gemmrvl6hp45u/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8f51nCFXWN from "/home/saimo/WebstormProjects/hub-rma-service/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_rnlbeninscff3gemmrvl6hp45u/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/saimo/WebstormProjects/hub-rma-service/.nuxt/components.plugin.mjs";
import prefetch_client_xHOKYj23qV from "/home/saimo/WebstormProjects/hub-rma-service/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_rnlbeninscff3gemmrvl6hp45u/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_hVYbOoz1tv from "/home/saimo/WebstormProjects/hub-rma-service/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_ioredis@5.4.1_rollup@4.18.0_vue-router@4.3.2_vue@3.4.27_typescript@5._4f3krzxyfz4hvrqbmmx2fp62r4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_KzDOGlg6DW from "/home/saimo/WebstormProjects/hub-rma-service/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_ioredis@5.4.1_rollup@4.18.0_vue-router@4.3.2_vue@3.4.27_typescript@5._4f3krzxyfz4hvrqbmmx2fp62r4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_VH5p5lAi4X from "/home/saimo/WebstormProjects/hub-rma-service/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_rnlbeninscff3gemmrvl6hp45u/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import directives_8CcCirWtnE from "/home/saimo/WebstormProjects/hub-rma-service/plugins/directives.ts";
import i18nCountries_iNv5VKvJL8 from "/home/saimo/WebstormProjects/hub-rma-service/plugins/i18nCountries.ts";
export default [
  revive_payload_client_8UYfML9Zsn,
  unhead_AxfMKDgrzJ,
  router_eTKVWLTuCf,
  payload_client_7NquL3Fccb,
  check_outdated_build_client_8f51nCFXWN,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xHOKYj23qV,
  composition_hVYbOoz1tv,
  i18n_KzDOGlg6DW,
  chunk_reload_client_VH5p5lAi4X,
  directives_8CcCirWtnE,
  i18nCountries_iNv5VKvJL8
]