export interface ModuleOptions {
  redirectIfNotAllowed: string | null | false
  fullAccessRoles: string | string[] | null
}

export type Roles = string[]
export type Permissions = string[]

export function useRoles () {
  return useState<Roles>('roles', () => [])
}

export function usePermissions () {
  return useState<Permissions>('permissions', () => [])
}

export function useHasPermisssion (permission: string | string[]) {
  const permissions = usePermissions()
  const activePermission = Array.isArray(permission) ? permission : [permission]
  return computed(() => activePermission.some(p =>
    permissions.value.includes(p)
  ))
}
