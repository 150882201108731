export interface ResponseLabel {
  label: string;
  paknr: string;
  saved: string;
  err_code: null | string;
  barcodecontent: string;
  kreferenz: string;
}

export interface ResponseRueckholauftrag {
  status: string;
  result: string;
}

export type ApiResponse = {
  status: string;
  result: ResponseLabel[] | ResponseRueckholauftrag;
}

export function isResponseRueckholauftrag (result: any): result is ResponseRueckholauftrag {
  return typeof result === 'string'
}

// map of countries supported by the API
export const labelCountries = [
  'BE', // Belgien
  'LT', // Litauen
  'DK', // Dänemark
  'LU', // Luxemburg
  'DE', // Deutschland
  'NL', // Niederlande
  'EE', // Estland
  'AT', // Österreich
  'FI', // Finnland
  'SE', // Schweden
  'FR', // Frankreich
  'SK', // Slowakei
  'IE', // Irland
  'CZ', // Tschechien
  'LV' // Lettland
]

export const pickupCountries = [
  'BG', // Bulgarien
  'ES', // Spanien
  'GR', // Griechenland
  'HR', // Kroatien
  'HU', // Ungarn
  'IT', // Italien
  'PL', // Polen
  'PT', // Portugal
  'RO', // Rumänien
  'SI' // Slowenien
]

type Empfaenger = {
  name: string;
  anschrift: string;
  kdnr: string; // assuming session.user.userId is a string
  zusatz: string;
  zusatz2: string;
  hausnr: string;
  tuernr: string;
  plz: string;
  ort: string;
  land: string;
  bezugsp: string;
  tel: string;
  mail: string;
};

type Paket = {
  liefernr: string; // assuming session.user.userId is a string
  rechnungsnr: string;
  pakettyp: string;
  gewicht: string;
};

type Absender = {
  name: string;
  addresse: string;
  addresse2: string;
  plz: string;
  ort: string;
  land: string;
};

export type LabelRequestBody = {
  service: string;
  function: string;
  data: {
    username: string;
    password: string;
    mandant: string;
    vdat: string;
    pakanz: string;
    empfaenger: Empfaenger;
    paket: Paket;
    produkt1: string;
    refnnbar: string;
    absender: Absender;
    dfu: string;
    format: string;
    kreferenz: string; // assuming session.user.userId is a string
    optionen: string;
  };
};

type UserDetails = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  country: string;
  zipCode: string;
  city: string;
  streetAddress: string;
  aptSuite: string;
};

type Details = {
  anmerkung: string;
  name: string;
  zusatz: string;
  land: string;
  plz: string;
  ort: string;
  anschrift: string;
  hausnr: string;
};

type RueckholauftragData = {
  username: string;
  password: string;
  mandant: string;
  datum: string;
  referenz: string;
  paktyp: string;
  produkt1: string;
  produkt2: string;
  anzahl: string;
  bemerkung: string;
  aname: string;
  azusatz: string;
  akontakt: string;
  aland: string;
  aplz: string;
  aort: string;
  astrasse: string;
  amail: string;
  ename: string;
  ezusatz: string;
  eland: string;
  eplz: string;
  eort: string;
  estrasse: string;
};

export type RueckholauftragRequest = {
  service: string;
  function: string;
  data: RueckholauftragData;
};
