import { useHasPermisssion } from '~/composables/permissions'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('allowed', {
    mounted (el, binding) {
      if (binding.arg === 'not') {
        if (useHasPermisssion(binding.value).value) {
          el.remove()
        }
        return
      }
      if (!useHasPermisssion(binding.value).value) {
        el.remove()
      }
    }
  })
})
