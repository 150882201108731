import validate from "/home/saimo/WebstormProjects/hub-rma-service/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_rnlbeninscff3gemmrvl6hp45u/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/home/saimo/WebstormProjects/hub-rma-service/middleware/01.auth.global.ts";
import manifest_45route_45rule from "/home/saimo/WebstormProjects/hub-rma-service/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_rnlbeninscff3gemmrvl6hp45u/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  protact: () => import("/home/saimo/WebstormProjects/hub-rma-service/middleware/protact.ts"),
  protected: () => import("/home/saimo/WebstormProjects/hub-rma-service/middleware/protected.ts"),
  "rma-permissions": () => import("/home/saimo/WebstormProjects/hub-rma-service/middleware/rma-permissions.ts")
}