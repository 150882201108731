<script lang="ts" setup>
const { rma } = useAppConfig()

useHead({
  title: 'Home',
  titleTemplate: `%s - ${rma.name}`
})
</script>

<template>
  <Body>
    <NuxtLayout data-theme="winter">
      <NuxtLoadingIndicator />
      <NuxtPage />
    </NuxtLayout>
  </Body>
</template>
